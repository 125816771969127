/* ChatbotCustomStyles.css */
.rcb-chat-window {
  background-color: #f2f2f2;
  width: 100vw;
  height: 100vh;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

/* .rcb-chatbot-body-container {
  color: #333;
  font-family: "Arial", sans-serif;
  font-size: 14px;
}

.rcb-chatinput {
  border-radius: 5px;
  padding: 10px;
} */

.rcb-toast-prompt-text {
  background-color: #282396;
  color: azure;
}
