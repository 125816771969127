.image-container {
  position: relative;
  width: 100%;
  margin: 0 auto;
}

/* Background image styling */
.background-image {
  width: 100%;
  height: auto;
  position: relative;
  filter: blur(3px); /* Apply blur effect */
  -webkit-filter: blur(3px); /* Safari compatibility */
  z-index: -1; /* Ensure background is behind other elements */
}

/* Keyframes for growing and shrinking animation */
@keyframes robot-grow-shrink {
  0% {
    transform: scale(1); /* Initial size */
  }
  50% {
    transform: scale(1.05); /* Grow slightly */
  }
  100% {
    transform: scale(1); /* Back to initial size */
  }
}

/* Keyframes for growing and shrinking animation */
@keyframes paper-grow-shrink {
  0% {
    transform: scaleX(-1); /* Initial size */
  }
  50% {
    transform: scaleX(-1.05); /* Grow slightly */
  }
  100% {
    transform: scaleX(-1); /* Back to initial size */
  }
}

/* Robot image styling */
.robot-image {
  position: absolute;
  top: 5%; /* Adjust as necessary */
  right: 5%; /* Adjust as necessary */
  width: 50%; /* Responsive size */
  height: auto;
  animation: robot-grow-shrink 2s infinite; /* 2 seconds duration, infinite repeat */
  cursor: pointer;
  transition: transform 0.3s ease;
  z-index: 1;
}

.robot-image:hover {
  animation-play-state: paused; /* Pause animation on hover */
  transform: scale(1.1);
}

/* Smart paper image styling */
.smart-paper-image {
  position: absolute;
  top: 15%; /* Adjust as necessary */
  right: 40%; /* Adjust as necessary */
  width: 15%; /* Responsive size */
  height: auto;
  opacity: 0.8;
  animation: paper-grow-shrink 3s infinite; /* 2 seconds duration, infinite repeat */
  cursor: pointer;
  transition: transform 0.3s ease;
  z-index: 2;
}

.smart-paper-image:hover {
  animation-play-state: paused; /* Pause animation on hover */
  transform: scaleX(1) scale(1.1); /* Keep the flip, but scale up on hover */
  opacity: 0.9; /* Fully opaque on hover */
}

footer {
  position: relative;
  z-index: 11111; /* Ensure the footer is above both the background and the robot image */
  background-color: #262f30;
}

/* Media queries for mobile responsiveness */
@media (max-width: 768px) {
  .robot-image {

    top: 8%;
    right: 5%;
    width: 70%; /* Smaller size for mobile */
  }

  .smart-paper-image {
    top: 15%;
    right: 55%;
    width: 25%; /* Smaller size for mobile */
  }
}
