/* Background image styling */
.background-container {
  background-image: url("/public/logo512.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  filter: blur(2px); /* Apply blur effect */
  -webkit-filter: blur(2px); /* Safari compatibility */
  z-index: -1; /* Ensure background is behind other elements */
}

@media (max-width: 768px) {
  .background-container {
    background-image: url("/public/logo192.png");
    filter: blur(1px); /* Apply blur effect */
    -webkit-filter: blur(1px); /* Safari compatibility */
  }
}
